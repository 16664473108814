import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { IconComponent } from '../../layout/widgets/icon/icon.component';

@Component({
  selector: 'app-add-new-button',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './add-new-button.component.html',
  styleUrl: './add-new-button.component.scss',
})
export class AddNewButtonComponent {
  @Input() label: string = '';
  @Output() addRequest = new EventEmitter();

  requestAddIetm() {
    this.addRequest.emit();
  }
}
